<template>
  <div>
    <h1 style="color: #f56c6c">人脸识别失败</h1>
    <div class="renclass" @click="toFace">点击这里重新发起人脸识别</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: 3,
    };
  },
  mounted() {
      
  },
  methods: {
    toFace() {
      this.$router.push({
        path: "backcenter",
        query: {
          id: btoa(JSON.parse(this.$route.query.params).idmsg),
        },
      });
    },
  },
};
</script>
<style scoped>
.renclass{
    font-size: .35rem;
    color: blue;
    text-align: center;
    text-decoration: underline;
}
h1,
h3 {
  text-align: center;
  margin: 0.5rem;
}
</style>